<template>
  <v-dialog
    v-model="isVisibleDialog"
    persistent
    max-width="80vw"
  >
    <v-form
      ref="form"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <span class="text-h3">{{ $t("edit") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="2">
                <select-date
                  v-model="options.date"
                  :label="$t('select_date_*')"
                  disabled
                  :min="dateValidation('min')"
                  :max="dateValidation('max')"
                  :rules="[rules.required]"
                />
              </v-col>
              <!-- <v-col cols="2">
                <v-select
                  v-model="options.vehcile_assignment"
                  dense
                  :label="$t('vehicle_assignment_*')"
                  :items="['manual', 'auto']"
                  :rules="[rules.required]"
                  @change="() => delete options.vehicle"
                />
              </v-col> -->
              <!-- v-if="options.vehcile_assignment == 'manual'" -->
              <v-col cols="3">
                <v-select
                  v-model="options.vehicle"
                  dense
                  disabled
                  :label="$t('select_vehicle_*')"
                  :items="vehicleList"
                  item-value="vehicle_id"
                  item-text="vehicle_name"
                  :rules="[rules.required]"
                />
              </v-col>

              <!-- <v-col cols="3">
                  <v-text-field
                    v-model="options.client_name"
                    dense
                    disabled
                    :label="$t('select_client_*')"
                    :rules="[rules.required]"
                  />
                </v-col> -->
              <v-col cols="2">
                <v-select
                  v-model="options.optimize_sequence"
                  dense
                  :disabled="isEditable"
                  :label="$t('optimize_sequence_*')"
                  :items="['yes']"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="2">
                <v-select
                  v-model="options.return_to_start"
                  dense
                  :disabled="isEditable"
                  :label="$t('return_to_start_location_*')"
                  :items="['yes', 'no']"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                v-if="isPermission('route-planning.delete')"
                class="d-flex justify-end"
              >
                <v-btn
                  class="mx-0"
                  color="red darken-1"
                  small
                  @click="() => (isDelete = !isDelete)"
                >
                  {{ $t("delete_job") }}
                </v-btn>
                <confirmation-modal
                  :is-visible="isDelete"
                  :toggle="toggleDeleteModal"
                />
              </v-col>

              <v-col cols="12">
                <add-new-job-table
                  v-model="options.jobs"
                  :items="options"
                  :disabled="isEditable"
                  @delete="onDeleteRow"
                />
                <!-- :locations="getLocations()" -->
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <small>{{ $t("indicates_required_field") }}</small>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="closeForm()"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="isEditable"
            :loading="isLoading"
            @click="updateItem()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";

export default {
  components: {
    selectDate: () => import("@/components/base/SingleDatePicker.vue"),
    AddNewJobTable: () => import("../utils/AddNewJobTable.vue"),
    ConfirmationModal: () =>
      import("@/components/Modals/ConfirmationModal.vue"),
  },
  mixins: [validationRules],
  props: {
    isVisibleDialog: Boolean,
    toggleEdit: {
      type: Function,
      default() {
        return void 0;
      },
    },
    tenantId: {
      type: [Number, String],
      default() {
        return null;
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      tab: 0,
      isLoading: false,
      //   options: {
      //     jobs: [{}],
      //   },
      options: {},
      isDelete: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      // isLoading: "routePlaning/getLoading",
      list: "routePlaning/getListByID",
      //
      //
      vehicleList: "routePlaning/getVehicleList",
      clientList: "routePlaning/getClientList",
    }),
    isEditable() {
      const st = this.list.map((r) => r.status);
      return !st.every((r) => r == "pending" || r == undefined);
    },
  },
  watch: {
    // "options.vehicle": {
    //   handler: function (v) {
    //     if (v) {
    //       const row = this.vehicleList.find((r) => r.vehicle == v);
    //       this.options.client = row.client;
    //       this.options.client_name = row.client_name;
    //     }
    //   },
    // },
  },
  async mounted() {
    // if (this.authUser.isAdmin) {
    // }
    // Fetch Data by Job ID
    await this.$store.dispatch("routePlaning/listByID", {
      job_id: this.item?.job_id,
    });
    this.setData(this.list);
  },
  methods: {
    isPermission(val) {
      return this.$admin.can(val);
    },
    async updateItem() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        await this.$store
          .dispatch("routePlaning/addJob", this.options)
          .then((res) => {
            this.$store.dispatch(
              "alerts/success",
              this.$t("updated_successfully")
            );
            this.isLoading = false;
            this.$emit("onSave", res);
            this.toggleEdit();
          })
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response?.data?.message);
            this.isLoading = false;
          });
      }
    },
    closeForm() {
      //   this.options = {};
      // this.$store.commit("csmsLocations/SET_LOCATION_IMG_LIST", []);
      this.toggleEdit();
    },
    onDeleteRow(v) {
      this.options.deleted_job_ids.push(v.id);
    },
    //
    setData(v) {
      const params = {
        operation_type: "edit",
        date: v[0].date,
        vehcile_assignment: "manual",
        vehicle: v[0].vehicle,
        vehicle_id: v[0].vehicle,
        optimize_sequence: v[0].optimize_sequence,
        return_to_start: v[0].return_to_start,
        tenant_id: this.tenantId,
        deleted_job_ids: [],
        jobs: v,
      };
      this.options = { ...params };
      //   this.options.vehicle = v[0]?.vehicle;
    },

    toggleDeleteModal(v) {
      if (v == "close" || v == "show") {
        return (this.isDelete = !this.isDelete);
      } else if (v == "ok") {
        this.deleteSelectedRow(this.item);
      }
    },
    async deleteSelectedRow(item) {
      const params = {
        job_id: item.job_id,
      };
      this.isLoading = true;
      await this.$store
        .dispatch("routePlaning/destroy", params)
        .then((res) => {
          this.$store.dispatch(
            "alerts/success",
            this.$t("deleted_successfully")
          );
          this.isLoading = false;
          this.$emit("onSave", res);
          this.toggleEdit();
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
          this.isLoading = false;
        });
    },
    //
    getCurrentDate() {
      const dt = new Date();
      const y = dt.getFullYear();
      let m = dt.getMonth() + 1;
      m = m < 10 ? `0${m}` : m;

      let d = dt.getDate();
      d = d < 10 ? `0${d}` : d;

      return `${y}-${m}-${d}`;
    },
    dateValidation(v) {
      const d = new Date();
      let day = d.getDate();

      if (v === "max") {
        day += 1;
        // Check if adding a day rolls over to the next month
        if (day > new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate()) {
          day = 1;
          d.setMonth(d.getMonth() + 1);
        }
      }

      // Format day and month with leading zeros
      day = day < 10 ? `0${day}` : day;
      const m = (d.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-indexed
      const y = d.getFullYear();

      const value = `${y}-${m}-${day}`;
      return value;
    },
  },
};
</script>
